'use client';

import useArticleId from '@haaretz/s-atoms/articleId';
import usePlatform from '@haaretz/s-atoms/platform';
import useDocumentEventListener from '@haaretz/s-use-native-application/useDocumentEventListener';
import useOnce from '@haaretz/s-use-once';
import React from 'react';

export default function useNativeAppInitialization() {
  const platform = usePlatform();
  const articleId = useArticleId();

  const isNativeApp = platform === 'app';

  useOnce(
    () => {
      window.webkit?.messageHandlers?.onDOMReady.postMessage('ready');
    },
    isNativeApp &&
      !!articleId &&
      typeof window !== 'undefined' &&
      typeof window.webkit?.messageHandlers?.onDOMReady.postMessage === 'function'
  );

  useOnce(
    () => {
      window.HtmlViewer?.onDOMReady(window.location.href);
    },
    isNativeApp &&
      !!articleId &&
      typeof window !== 'undefined' &&
      typeof window.HtmlViewer?.onDOMReady === 'function'
  );

  const onLoadElement = React.useCallback((event: CustomEvent<(string | undefined)[]>) => {
    const args = event.detail || [];

    /* istanbul ignore else */
    if (args[0]) {
      window.deviceId = args[0];
    }

    /* istanbul ignore else */
    if (args[1]) {
      window.appVersion = args[1];
    }

    /* istanbul ignore else */
    if (args[2]) {
      window.anonymousId = args[2];
    }

    /* istanbul ignore else */
    if (args[3]) {
      window.androidId = args[3];
    }
  }, []);

  useDocumentEventListener('loadElement', onLoadElement, false);
}
